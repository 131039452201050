import * as React from "react";
import { graphql, Link, navigate } from "gatsby";
import { useEffect } from "react";
import styled from "styled-components";
import Map from "../images/svg/map_momh.svg";
import { motion } from "framer-motion";
import PageCopy from "../components/PageCopy";

const MapContainer = styled.div`
  text-align: center;
  display: block;
  padding: 2em;
  width: 100%;
  svg {
    width: 100%;
    height: auto;
    transition: all 2s ease-in-out;
  }
  .artwork {
    stroke: blue;
  }
  .artworkMarker&:hover {
    transform: scale(1.5);
    stroke: red;
    fill: red;
  }
`;

export default function Museum({ data }) {
  const artworks = data.artworks.nodes;
  const pageCopy = data.pageCopy.museumIntro.split("\n");

  useEffect(() => {
    console.log(artworks);
    if (document.getElementsByClassName("artworkMarker").length === 0) {
      artworks.map((artwork, i) => createArtwork(artwork, i));
    }
  });

  function createArtwork(artwork, i) {
    // console.log({ artwork.locationX, artwork.locationY })
    const svgns = "http://www.w3.org/2000/svg";
    const marker = {
      shortEdge: 2,
      longEdge: 2,
      lineWidth: 2,
    };

    //First, convert the percentage value to a position value based on the actual map displayed
    const xPosition =
      document.querySelector("#mapSvg").getBBox().width *
      (artwork.locationX / 100);
    const yPosition =
      document.querySelector("#mapSvg").getBBox().height *
      (artwork.locationY / 100);

    let artworkRectangle = document.createElementNS(svgns, "rect");
    artworkRectangle.style.strokeWidth = "0.1";
    artworkRectangle.title = artwork.title;
    if (artwork.orientation === "horizontal") {
      artworkRectangle.setAttribute("height", marker.shortEdge);
      artworkRectangle.setAttribute("width", marker.longEdge);
    } else {
      artworkRectangle.setAttribute("height", marker.longEdge);
      artworkRectangle.setAttribute("width", marker.shortEdge);
    }
    artworkRectangle.setAttribute("x", xPosition * Math.random());
    //TODO random spot within the map (X and Y), then coming to rest on final spot?
    artworkRectangle.setAttribute("y", yPosition);
    artworkRectangle.setAttribute(
      "transform",
      "translate(40.315321,108.10831)"
    );
    artworkRectangle.classList.add("artworkMarker");
    artworkRectangle.id = `${artwork.slug.current}`;
    artworkRectangle.setAttribute("title", artwork.title);
    artworkRectangle.setAttribute("data-index", i);
    document.querySelector("#layer1").appendChild(artworkRectangle);
    setTimeout(function () {
      artworkRectangle.setAttribute("x", xPosition);
      artworkRectangle.style.fillOpacity = "1";
      artworkRectangle.style.strokeOpacity = "1";
    }, Math.random() * 1500);

    document.querySelectorAll(".artworkMarker").forEach((artwork) => {
      artwork.addEventListener("click", function () {
        navigate(`/art/${artwork.id}`);
      });
    });
  }

  return (
    <>
      <MapContainer>
        <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 2 }}>
          <Map id="mapSvg" />
        </motion.div>
      </MapContainer>
      <PageCopy pageCopy={pageCopy} />
    </>
  );
}

export const query = graphql`
  query {
    artworks: allSanityArt {
      nodes {
        id
        title
        slug {
          current
        }
        orientation
        medium
        locationY
        locationX
      }
    }
    pageCopy: sanityWebsiteCopy {
      museumIntro
    }
  }
`;
